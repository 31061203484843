import {OTPInput, SlotProps} from 'input-otp';
import {useCallback, useState} from 'react';

const digits = 6;

/**
 * @callback onComplete
 * @param {string} value
 * @return {Promise<boolean>} true/false for if code is correct or not
 */

/**
 * @param {onComplete} onComplete
 */
export function InputOTP({onComplete, ...props}) {
    // input-otp does not expose a way to reset the input value. Instead, this resets the entire component state
    const [attempt, setAttempt] = useState(0);

    const onChange = useCallback((value) => {
        if (value.length === digits) {
            onComplete(value).then(correct => {
                if (!correct) {
                    setAttempt((prev) => prev + 1);
                }
            });
        }
    }, [onComplete]);

    return <OTPInput key={attempt} {...props} autoFocus={true} required={true} textAlign={'center'} maxLength={digits} minLength={digits} onChange={onChange} render={({slots}) => {
        return <div className={'hstack gap-2'}>
            <DigitInput {...slots[0]}/>
            <DigitInput {...slots[1]}/>
            <DigitInput {...slots[2]}/>
            <DigitInput {...slots[3]}/>
            <DigitInput {...slots[4]}/>
            <DigitInput {...slots[5]}/>
        </div>;
    }}/>;
}

/**
 * @param {SlotProps} props
 */
function DigitInput(props) {
    // Emulates `.form-control:focus` by applying darken(@border-gray, 10%)
    const style = props.isActive ? {borderColor: '#b9bcd6'} : undefined;

    return <div className={'form-control input-lg text-center text-bold'} style={style}>{props.char}</div>;
}
