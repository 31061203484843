import {setWaitingInterval, translate} from '../utils';
import $ from 'jquery';
import {disableSubmitButton, enableSubmitButton} from '../enable_disable_button';
import {createRoot} from 'react-dom/client';
import {StrictMode, createElement} from 'react';
import {InputOTP} from '../components/InputOTP';
import {flushSync} from 'react-dom';

function checkCsrfToken() {
    if (document.hidden) {
        return Promise.resolve();
    }

    return $.post('/site/check-token').then(function (response) {
        if (response === false) {
            intervalHandle();
            document.getElementById('csrfWarning').classList.remove('hidden');
        }
    });
}

const intervalHandle = setWaitingInterval(checkCsrfToken, 5 * 1000);

let submitPromise = null;
let submitPromiseResolve = null;
const loginForm = document.getElementById('loginForm');
const $loginForm = $(loginForm);

$loginForm.on('beforeValidate', () => {
    // `beforeSubmit` event is called with a 200ms delay. To avoid double click,
    // disabling the button in `beforeSubmit` wont work if user is fast enough
    if ($loginForm.yiiActiveForm('data').submitting) {
        disableSubmitButton(
            loginForm.querySelector('button[type="submit"]'),
        );

        submitPromise = new Promise((resolve) => {
            submitPromiseResolve = resolve;
        });
    }
}).on('afterValidate', (event, messages, errorAttributes) => {
    if (errorAttributes.length > 0 && $loginForm.yiiActiveForm('data').submitting) {
        submitPromiseResolve(false);
        submitPromise = submitPromiseResolve = null;
        enableSubmitButton(
            loginForm.querySelector('button[type="submit"]'),
        );
    }
}).on('beforeSubmit', () => {
    hideError();

    fetch(loginForm.action, {
        method: loginForm.method,
        body: new FormData(loginForm),
        headers: {
            'Accept': 'application/json'
        },
    }).then(response => {
        return response.json();
    }).then(body => {
        if (typeof body === 'string') {
            submitPromiseResolve(true);
            submitPromise = submitPromiseResolve = null;
            window.location.href = body;
        } else {
            submitPromiseResolve(false);
            submitPromise = submitPromiseResolve = null;

            if (Array.isArray(body)) {
                showCredentialsStep();
                showError(body[0]);
            } else if (Object.keys(body).length === 1 && body.otp) {
                if (otpRoot) {
                    showError(body.otp);
                }
                showOtpStep();
            } else {
                for (let field in body) {
                    if (field !== 'otp') {
                        showError(body[field]);
                        break;
                    }
                }
                showCredentialsStep();
            }

            enableSubmitButton(
                loginForm.querySelector('button[type="submit"]'),
            );
        }
    }).catch(() => {
        if (submitPromiseResolve) {
            submitPromiseResolve(false);
            submitPromise = submitPromiseResolve = null;
        }

        showError(translate('frontend', 'ERROR_INPROCESS'));

        enableSubmitButton(
            loginForm.querySelector('button[type="submit"]'),
        );
    });

    return false;
});

function onOtpComplete() {
    loginForm.querySelector('button[type="submit"]').click();
    return submitPromise;
}

function showError(error) {
    const errorEl = document.getElementById('formError');
    errorEl.classList.remove('hidden');
    errorEl.innerText = error;
}

function hideError() {
    document.getElementById('formError').classList.add('hidden');
}

let otpRoot = null;

function showOtpStep() {
    document.getElementById('credentialFields').classList.add('hidden');
    document.getElementById('credentialsIntro').classList.add('hidden');
    document.getElementById('forgotPassword')?.classList.add('hidden');

    document.getElementById('otpFields').classList.remove('hidden');
    document.getElementById('otpIntro').classList.remove('hidden');

    if (!otpRoot) {
        const otpFields = document.getElementById('otpFields');
        otpRoot = createRoot(otpFields)
        flushSync(() => {
            otpRoot.render(
                createElement(StrictMode, null, createElement(InputOTP, {
                    onComplete: onOtpComplete,
                    name: otpFields.dataset.inputName,
                })),
            );
        })
    }
}

function showCredentialsStep() {
    document.getElementById('credentialFields').classList.remove('hidden');
    document.getElementById('credentialsIntro').classList.remove('hidden');
    document.getElementById('forgotPassword')?.classList.remove('hidden');

    document.getElementById('otpFields').classList.add('hidden');
    document.getElementById('otpIntro').classList.add('hidden');

    if (otpRoot) {
        flushSync(() => {
            otpRoot.unmount();
        });
        otpRoot = null;
    }
}
